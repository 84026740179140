<template>
  <div>
    <v-form v-model="valid" class="container">
      <h1 class="text-h1 text--white ma-8 text--center">Pós-vendas Gali</h1>
      <p class="text-h6 text--white ma-8 text--center">
        Seja bem-vindo a central de apoio ao cliente em atendimentos de
        pós-vendas da Gali energia.<br />
        Preencha os dados abaixo para que possamos realizar a abertura do ticket
        de atendimento.
      </p>
      <v-stepper v-model="e1" vertical color="secondary">
        <v-stepper-step :complete="e1 > 1" step="1">
          Dados Pessoais
        </v-stepper-step>
        <v-stepper-content step="1">
          <h3 class="text-subtitle-1 mb-4">
            Os dados abaixo devem ser preenchidos para melhor comunicação e
            interação entre nossa equipe e você.
          </h3>
          <info-inicial
            :dados.sync="dadosPessoais"
            @changePagina="mudarPagina"
          ></info-inicial>
        </v-stepper-content>

        <template v-if="possuiSatisfacaoAberta">
          <v-stepper-step :complete="e1 > 2" step="2">
            Satisfações Abertas
          </v-stepper-step>
          <v-stepper-content step="2">
            <h3 class="text-subtitle-1 mb-4">
              Foram encontrados alguns tickets finalizados no seu email, deseja
              responder nossa pesquisa de satisfação?
            </h3>

            <h2>{{ satisfacoesAbertas[0].email }}</h2>
            <div v-for="satisfacao in satisfacoesAbertas" :key="satisfacao">
              <v-spacer></v-spacer>
              <v-card-text>
                <v-row class="pa-3">
                  <v-col style="font-size: 1.3em; display: flex">
                    <p style="font-weight: 600">
                      {{ satisfacao.ticket }} -&nbsp;
                    </p>
                    {{ exibeData(satisfacao.dataAbertura) }}</v-col
                  >
                  <v-col cols="3">
                    <v-btn color="primary" @click="abreSatisfacao(satisfacao)">
                      <v-icon> mdi-open-in-new </v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </div>

            <div v-if="satisfacoesAbertasObra.length">
              <h2>{{ satisfacoesAbertasObra[0].email }}</h2>
              <div
                v-for="satisfacao in satisfacoesAbertasObra"
                :key="satisfacao"
              >
                <v-spacer></v-spacer>
                <v-card-text>
                  <v-row class="pa-3">
                    <v-col style="font-size: 1.3em; display: flex">
                      <p style="font-weight: 600">
                        {{ satisfacao.ticket }} -&nbsp;
                      </p>
                      {{ exibeData(satisfacao.dataAbertura) }}</v-col
                    >
                    <v-col cols="3">
                      <v-btn
                        color="primary"
                        @click="abreSatisfacao(satisfacao)"
                      >
                        <v-icon> mdi-open-in-new </v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </div>

            <v-btn variant="text" class="mb-12 ml-4" @click="next(1)">
              <v-icon>mdi-arrow-left</v-icon> Voltar</v-btn
            >
            <v-btn color="secondary" class="mb-12 ml-4" @click="next(3)"
              >Não</v-btn
            >
          </v-stepper-content>
        </template>

        <v-stepper-step
          :complete="e1 > 3"
          :step="possuiSatisfacaoAberta ? '3' : '2'"
        >
          Informações técnicas:
        </v-stepper-step>

        <v-stepper-content :step="possuiSatisfacaoAberta ? 3 : 2">
          <h3 class="text-subtitle-1 mb-4">
            Preencha os campos com as informações essenciais para direcionarmos
            o tipo de atendimento solicitado.
          </h3>

          <info-problema
            :dados.sync="dadosTecnicos"
            @changePagina="next"
          ></info-problema>

          <!-- <v-btn color="secondary" @click="e1 = 0"> Próximo </v-btn> -->

          <!-- <v-btn text @click="e1 = e1-1"> Voltar </v-btn> -->

          <v-col cols="12">
            <p class="text-subtext-1 font-weight-bold text-center">
              *Ao preencher e clicar em enviar voce está dando consentimento
              para o armazenamento e uso dos seus dados para posterior contato
              pela Gali. Estes dados ficam a disposição da empresa pelo período
              que for necessário para atendimento.
            </p>
          </v-col>
        </v-stepper-content>
      </v-stepper>

      <v-snackbar
        style="position: fixed"
        :timeout="5000"
        :value="err"
        absolute
        center
        shaped
        bottom
        elevation="12"
        color="red accent-2"
        app
      >
        Ocorreu um erro <strong>ao enviar</strong> o formulário.
        <br />
        Por favor, tente novamente mais tarde.
      </v-snackbar>
    </v-form>
  </div>
</template>

<script>
import InfoInicial from "./InfoInicial.vue";
import InfoProblema from "./InfoProblema.vue";
// eslint-disable-next-line no-unused-vars
import API from "../api/API";
import jwt from "jsonwebtoken";

export default {
  components: {
    InfoInicial,
    InfoProblema,
  },
  data: () => ({
    valid: false,
    dialog: false,
    e1: 1,
    dadosPessoais: {},
    dadosTecnicos: {},
    err: false,
    satisfacoesAbertas: [],
    satisfacoesAbertasObra: [],
  }),
  computed: {
    // a computed getter
    possuiSatisfacaoAberta: function () {
      // `this` points to the vm instance
      this.satisfacoesAbertas;
      this.satisfacoesAbertasObra;
      return !!(
        this.satisfacoesAbertas.length || this.satisfacoesAbertasObra.length
      );
    },
  },
  methods: {
    abreSatisfacao(satisfacao) {
      const token = jwt.sign({ idSatisfacao: satisfacao.SK }, "GALIENERGIA", {
        expiresIn: "1 days",
      });

      let route = this.$router.resolve({
        name: "FormularioSatisfacao",
        params: { ticketJwt: token },
      });
      window.open(`#${route.resolved.fullPath}`, "_blank");
    },

    async verificaSatisfacoesAbertas() {
      this.satisfacoesAbertas = [];
      this.satisfacoesAbertasObra = [];
      this.satisfacoesAbertas = await API.buscarSatisfacaoByEmail(
        this.dadosPessoais.email
      );
      this.satisfacoesAbertasObra = await API.buscarSatisfacaoByEmail(
        this.dadosPessoais.emailResponsavelObra
      );

      const todasAbertas = this.satisfacoesAbertas.concat(
        this.satisfacoesAbertasObra
      );
      console.log("todasAbertas", todasAbertas);
    },
    async mudarPagina(page) {
      console.log("mudarPagina", page);
      await this.verificaSatisfacoesAbertas();
      this.next(page);
    },
    async next(page) {
      if (page) this.e1 = page;
      else await this.enviarForm();
    },
    async enviarForm() {
      this.$emit("carregando", true);
      this.err = false;
      try {
        const formCompleto = {
          ...this.dadosPessoais,
          ...this.dadosTecnicos,
        };
        const resp = await API.enviarForm(formCompleto);
        if (resp.status !== 200) throw resp;
        this.$emit("carregando", false);
        this.$emit("finalizado", {
          status: true,
          ticket: resp.data.id_ticket,
        });
      } catch (error) {
        this.err = true;
        this.$emit("carregando", false);
      }
    },
    exibeData(data) {
      return new Date(data).toLocaleString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });
    },
  },
};
</script>

<style lang="scss">
.text--white {
  color: white;
}
.text--center {
  text-align: center;
}
.form-principal {
  display: flex;
  flex-direction: column;
}
</style>
