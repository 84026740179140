<template>
  <v-row>
    <v-col cols="12">
      <label for=""
        >Preencha em qual categoria encontra-se sua necessidade de suporte
        técnico:</label
      >
      <v-select
        v-model.trim="$v.categoriaSelecionada.$model"
        :error-messages="categoriaErrors"
        :items="categorias"
        label="Selecione uma categoria"
        solo
      ></v-select>
    </v-col>

    <v-col cols="12">
      <label for=""
        >Selecione uma opção para o tipo de suporte técnico que deseja:</label
      >
      <v-select
        v-model.trim="$v.suporteSelecionado.$model"
        :error-messages="suporteErrors"
        :items="tiposSuporte"
        item-text="texto"
        item-value="valor"
        label="Selecione um tipo"
        solo
        @change="suporteChange"
      ></v-select>
    </v-col>

    <!-- GARANTIA ---------------- -->
    <v-row
      class="opcional-container"
      v-if="
        $v.suporteSelecionado.$model === 'GARANTIA' ||
        suporteSelecionado === 'REPARO' 
      "
    >
      <v-col cols="12">
        <label for=""
          >Descreva o problema reportado no equipamento para análise
          {{
            suporteSelecionado === "GARANTIA"
              ? " de garantia:"
              : "sobre procedimentos para reparo:"
          }}
        </label>
        <v-textarea
          v-model.trim="$v.descricao.$model"
          :error-messages="descricaoErrors"
          label=""
          placeholder="Insira sua resposta"
          solo
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <label for=""
          >Informe o modelo do equipamento e número de série:
        </label>
        <v-text-field
          v-model.trim="$v.nSerie.$model"
          :error-messages="nSerieErrors"
          label="Informe o modelo do equipamento e número de série:"
          placeholder="Insira sua resposta"
          solo
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <label for="">Desde qual data o equipamento está em operação ? </label>
        <v-text-field
          :error-messages="dataInicioOperacaoErrors"
          v-model.trim="$v.dataInicioOperacao.$model"
          label="Desde qual data o equipamento está em operação ? "
          placeholder="Nome"
          type="date"
          solo
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <label for=""
          >O equipamento/produto possui plano de manutenção preventiva conforme
          manual do fabricante?
        </label>
        <v-switch
          v-model="manutencaoPreventiva"
          inset
          :label="`${manutencaoPreventiva ? 'SIM' : 'NÃO'}`"
        ></v-switch>
      </v-col>

      <v-col cols="12" md="4">
        <label for=""
          >Referente ao problema reportado, existe relatório de ocorrência
          registrando os detalhes, fotos e evidências?
        </label>
        <v-switch
          v-model="temRelatorio"
          inset
          :label="`${temRelatorio ? 'SIM' : 'NÃO'}`"
        ></v-switch>
      </v-col>

      <!-- ============= INPUT MANUAL MANUTENÇÃO -->
      <v-col cols="12" v-show="manutencaoPreventiva">
        <label for=""
          >Para auxiliar no atendimento nos encaminhe os relatórios de
          manutenção preventiva do equipamento</label
        >
        <v-file-input
          v-model="$v.manuais.$model"
          :error-messages="manuaisErrors"
          color="deep-purple accent-4"
          counter
          multiple
          placeholder="Manuais e relatórios de manutenção"
          prepend-icon="mdi-paperclip"
          outlined
          solo
          :show-size="1000"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
              v-if="index < 2"
              color="deep-purple accent-4"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>

            <span
              v-else-if="index === 2"
              class="text-overline grey--text text--darken-3 mx-2"
            >
              +{{ files.length - 2 }} Arquivos(s)
            </span>
          </template>
        </v-file-input>
      </v-col>
      <!-- ============ INPUT RELATORIO OCORRENCIA -->
      <v-col cols="12" v-show="temRelatorio">
        <label for=""
          >Para auxiliar no atendimento nos encaminhe as evidencias fotográficas
          e relatórios de registro da ocorrência</label
        >
        <v-file-input
          v-model="$v.files.$model"
          :error-messages="filesErrors"
          color="deep-purple accent-4"
          counter
          multiple
          placeholder="Evidencias e registros da ocorrência"
          prepend-icon="mdi-paperclip"
          outlined
          solo
          :show-size="1000"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
              v-if="index < 2"
              color="deep-purple accent-4"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>

            <span
              v-else-if="index === 2"
              class="text-overline grey--text text--darken-3 mx-2"
            >
              +{{ files.length - 2 }} Arquivos(s)
            </span>
          </template>
        </v-file-input>
      </v-col>

      <v-col cols="12" v-show="!temRelatorio">
        <label for=""
          >Por favor, explique a falta de evidências da ocorrência
        </label>
        <v-text-field
          v-model.trim="$v.obsEvidencias.$model"
          :error-messages="obsEvidenciasError"
          label="Por favor, explicar a falta de evidências da ocorrência"
          placeholder="Insira sua resposta"
          solo
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- OUTROS ---------------- -->
    <v-row
      class="opcional-container"
      v-if=" $v.suporteSelecionado.$model === 'OUTROS' "
    >
      <v-col cols="12">
        <label for="">Especifique:</label>
        <v-textarea
          v-model.trim="$v.outrosDesc.$model"
          :error-messages="outrosDescError"
          label="Especifique:"
          placeholder="Especifique"
          solo
        ></v-textarea>
      </v-col>
    </v-row>

    <v-col
      cols="12"
      v-show="Object.keys($v.suporteSelecionado.$model).length"
      class="mb-4"
    >
      <label for=""
        >Em breve um de nossos atendentes realizará o contato direto para seguir
        com seu atendimento. Para que possamos melhorar nossos processos bem
        como a qualidade de nossos atendimentos poderia avaliar nossa triagem
        online?</label
      >
      <v-rating
        v-model="avaliacao"
        hover
        half-increments
        :length="5"
        size="46"
        :value="5"
        color="secondary"
      ></v-rating>
    </v-col>

    <v-col class="col-12 mb-4">
      <v-btn color="secondary" @click="proximaPagina"> Enviar </v-btn>
      <v-btn text @click="voltar"> Voltar </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {
  minLength,
  maxLength,
  required,
  requiredIf,
  requiredUnless,
} from "vuelidate/lib/validators";

const isValidFileSize = (value) => {
  
    if (!value || value.length === 0) {
      return true
    }
    let totalSize = 0
    value.forEach(file => {
      const fileSizeinKb = file.size / 1024
      totalSize += fileSizeinKb
    });
    const size = Math.round(totalSize * 100) / 100 // convert up to 2 decimal place
    return size <= (20971520 / 1024) //6291456
}
export default {
  name: "InfoInicial",
  data: () => ({
    avaliacao: 5,
    categorias: [
      "Infraestrutura de equipamentos (Bases metálicas, Pinturas, Portas, Compartimentos, Suportes).",
      "Elétrica (CCM,PGBT,PNL Supervisão, Sinbox, Caixa de Sensores, Caixas de Passagem, Sistemas de Combate a Incêndio).",
      "Automação (CFTV, Supervisório, Scada,Parametrizações de Relés,Inversores,Controladores e Softstarters).",
      `Civil (Estacas, Motoras, Trackers, Bases de Equipamentos, Infraestrutura Geral de UFV's).`,
      "Outros",
    ],
    tiposSuporte: [
      {
        texto: "Reparo de produtos, equipamentos ou serviços adicionais",
        valor: "REPARO",
      },
      {
        texto: "Garantia de produtos, equipamentos ou serviços",
        valor: "GARANTIA",
      },
      { texto: "Outros", valor: "OUTROS" },
    ],
    categoriaSelecionada: {},
    suporteSelecionado: {},
    manutencaoPreventiva: false,
    temRelatorio: false,
    files: [],
    //CAMPOS ESPECIFICOS ===========
    descricao: "",
    nSerie: "",
    dataInicioOperacao: "",
    outrosDesc: "",
    obsEvidencias: "",
    manuais: [],
  }),
  validations() {
    let validations = {
      categoriaSelecionada: {
        required,
      },
      suporteSelecionado: {
        required,
      },
      descricao: {
        required: requiredIf("temCategoria"),
        minLength: minLength(3),
        maxLength: maxLength(600),
      },
      nSerie: {
        required: requiredIf("temCategoria"),
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      dataInicioOperacao: {
        required: false,
      },

      files: {
        required: requiredIf("temRelatorioBoolean"),
        isValidFileSize,
      },
      obsEvidencias: {
        required: requiredIf("naoRelatorio"),
        minLength: minLength(3),
        maxLength: maxLength(600),
      },
      manuais: {
        required: requiredIf("temManutencaoPreventiva"),
        isValidFileSize,
      },

      outrosDesc: {
        required: requiredUnless("temCategoria"),
        minLength: minLength(3),
        maxLength: maxLength(600),
      },
    };
    // if(this.temRelatorio)
    // validations = {...validations,
    //   files: {
    //     required,
    //   }
    // }
    return validations;
  },
  computed: {
    temCategoria() {
      const retorno = this.suporteSelecionado !== "OUTROS";
      return retorno;
    },
    temRelatorioBoolean() {
      return this.temRelatorio && this.temCategoria;
    },
    naoRelatorio() {
      return !this.temRelatorio && this.temCategoria;
    },
    temManutencaoPreventiva() {
      return this.manutencaoPreventiva && this.temCategoria;
    },
    categoriaErrors() {
      const errors = [];
      if (!this.$v.categoriaSelecionada.$dirty) return errors;
      !this.$v.categoriaSelecionada.required &&
        errors.push("Campo obrigatório");

      return errors;
    },
    suporteErrors() {
      const errors = [];
      if (!this.$v.suporteSelecionado.$dirty) return errors;
      !this.$v.suporteSelecionado.required && errors.push("Campo obrigatório");

      return errors;
    },
    manutencaoErrors() {
      const errors = [];
      if (!this.$v.manutencaoPreventiva.$dirty) return errors;
      !this.$v.manutencaoPreventiva.required &&
        errors.push("Campo obrigatório");

      return errors;
    },
    temRelatorioErrors() {
      const errors = [];
      if (!this.$v.temRelatorio.$dirty) return errors;
      !this.$v.temRelatorio.required && errors.push("Campo obrigatório");

      return errors;
    },
    descricaoErrors() {
      const errors = [];
      if (!this.$v.descricao.$dirty) return errors;
      !this.$v.descricao.minLength &&
        errors.push("O campo deve conter pelo menos 3 caracteres ");
      !this.$v.descricao.maxLength &&
        errors.push("O campo deve conter no máximo 50 caracteres");
      !this.$v.descricao.required && errors.push("Campo obrigatório");

      return errors;
    },
    nSerieErrors() {
      const errors = [];
      if (!this.$v.nSerie.$dirty) return errors;
      !this.$v.nSerie.minLength &&
        errors.push("O campo deve conter pelo menos 3 caracteres ");
      !this.$v.nSerie.maxLength &&
        errors.push("O campo deve conter no máximo 50 caracteres");
      !this.$v.nSerie.required && errors.push("Campo obrigatório");

      return errors;
    },
    dataInicioOperacaoErrors() {
      const errors = [];
      if (!this.$v.dataInicioOperacao.$dirty) return errors;
      !this.$v.dataInicioOperacao.required && errors.push("Campo obrigatório");

      return errors;
    },
    filesErrors() {
      const errors = [];
      if (!this.$v.files.$dirty) return errors;
      !this.$v.files.isValidFileSize && errors.push("Envie no máximo 20MB");
      !this.$v.files.required && errors.push("Campo obrigatório");

      return errors;
    },
    outrosDescError() {
      const errors = [];
      if (!this.$v.outrosDesc.$dirty) return errors;
      !this.$v.outrosDesc.minLength &&
        errors.push("O campo deve conter pelo menos 3 caracteres ");
      !this.$v.outrosDesc.maxLength &&
        errors.push("O campo deve conter no máximo 600 caracteres");
      !this.$v.outrosDesc.required && errors.push("Campo obrigatório");
      return errors;
    },
    obsEvidenciasError() {
      const errors = [];
      if (!this.$v.obsEvidencias.$dirty) return errors;
      !this.$v.obsEvidencias.minLength &&
        errors.push("O campo deve conter pelo menos 3 caracteres ");
      !this.$v.obsEvidencias.maxLength &&
        errors.push("O campo deve conter no máximo 600 caracteres");
      !this.$v.obsEvidencias.required && errors.push("Campo obrigatório");

      return errors;
    },
    manuaisErrors() {
      const errors = [];
      if (!this.$v.manuais.$dirty) return errors;
      !this.$v.manuais.isValidFileSize && errors.push("Envie no máximo 6MB");
      !this.$v.manuais.required && errors.push("Campo obrigatório");

      return errors;
    },
  },
  methods: {
    suporteChange(tipo) {
      if (tipo === "OUTROS") {
        this.$v.$reset();
        this.manutencaoPreventiva = false;
        this.temRelatorio = false;
        this.files = [];
        this.descricao = "";
        this.nSerie = "";
        this.dataInicioOperacao = "";
        this.outrosDesc = "";
      }
    },
    proximaPagina() {
      if (this.submit()) {
        this.$emit("update:dados", {
          categoria: this.categoriaSelecionada,
          tipoSuporte: this.suporteSelecionado,
          manutencaoPreventiva: this.manutencaoPreventiva,
          temRelatorio: this.temRelatorio,
          files: this.files,
          manuais: this.manuais,
          descricao: this.descricao,
          nSerie: this.nSerie,
          dataInicioOperacao: this.dataInicioOperacao,
          outrosDesc: this.outrosDesc,
          obsEvidencias: this.obsEvidencias,
          avaliacao: this.avaliacao,
        });
        this.$emit("changePagina", 0);
      } else {
        //
      }
    },
    voltar() {
      this.$emit("changePagina", 1);
    },
    submit() {
      this.$v.$touch()
      return !this.$v.$invalid;
    },
  },
};
</script>

<style lang="scss">
.form-principal {
  display: flex;
  flex-direction: column;
}
label {
  max-width: 99%;
}
.opcional-container {
  margin: 0px;
  max-width: 100%;
  background-color: #e8e8e8;
  width: 100%;
  padding: 12px 24px;
  margin-bottom: 24px;
}
</style>
