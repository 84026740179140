<template>
  <v-app class="primary" :style="`height: 100vh; ${(carregando)?'overflow: hidden':''}`">
    
    <v-overlay :value="carregando" absolute style="height: 100vh">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-main class="primary">
      <FormPosVendas @carregando="toogleLoading" @finalizado="finalizar" v-if="!finalizado"/>
      <div 
      v-else 
      style="height: 100vh; width: 100vw"
      class="d-flex flex-column align-center justify-center"
      >
        <div class="d-flex justify-end pr-8 pr-sm-12" style="position: fixed; bottom:24px; width: 100vw">
          <img src="../assets/logo-obrigado.png" style="width: 40%; max-width: 200px;"/>

        </div>

        <h1 class="text-h2 text-sm-h1 text--white ma-8 text--center">
          Obrigado!
        </h1>
        <h1 class="text-h4 text-sm-h3 text--white ma-4 text--center">
          Formulário enviado com sucesso. 
        </h1 >
        <h2 class="text-h4 text-sm-h3 text--white ma-2 text--center">
          Ticket nº {{ticket}}
        </h2>
        <h2 class="text-h6 text-sm-h5 text--white ma-2 text--center">
          Logo nosso time entrará em contato
        </h2>

        <h3 class=" text-sm-7 text--white mt-12 text--center">
          Para mais informações acesse no canal no Whatsapp
        </h3>

        <v-btn
          class="ma-8"
          fab
          dark
          color="green"
          @click="abrirWhats()"
        >
          <v-icon >
            mdi-whatsapp
          </v-icon>
        </v-btn>

        <!-- <v-btn color="secondary" class="ma-12">Finalizar</v-btn> -->
      </div>
    </v-main>
  </v-app>
</template>

<script>
import FormPosVendas from '../components/FormPosVendas.vue';

export default {
  name: 'FormularioPosVendas',

  components: {
    FormPosVendas,
  },

  data: () => ({
    //
    carregando: false,
    finalizado: false,
    timer: null, 
    ticket: ''
  }),
  created(){
    this.finalizado = false
  },
  methods: {
    toogleLoading(value){
      const vue =  this
      if(!value)
        this.timer = setInterval(() => {
            clearInterval(vue.timer);
            vue.carregando = false;
        }, 1000);
      else 
        this.carregando = value
    },
    abrirWhats(){
      // https://wa.me/?text=urlencodedtext
      const text = encodeURI(`Olá, gostaria de mais informações sobre o ticket *${this.ticket}*`)
      const GRUPO_LINK = `https://wa.me/5514998628427?text=${text}`
      window.open(GRUPO_LINK, '_blank')
    },
    finalizar(values){
      this.ticket = values.ticket
      this.finalizado = true
    }
  }
};
</script>
