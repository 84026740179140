<template>
      <v-row>
        <v-col
          cols="12"
        >
          <label for="">Nome do Solicitante:</label>
          <v-text-field
            label="Nome do Solicitante:"
            placeholder="Nome"
            solo
            :error-messages="nameErrors"
            v-model.trim="$v.nome.$model"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
        >
          <label for="">Cargo:</label>

          <v-select
            v-model.trim="$v.cargo.$model"
            :error-messages="cargoErrors"
            :items="cargos"
            label="Selecione uma categoria"
            solo
          ></v-select>
        </v-col>


        <v-col
          cols="12" md="8"
        >
          <label for="">Cliente</label>
          <v-text-field
            label="Cliente"
            placeholder="Razão social Cliente"
            solo
            v-model.trim="$v.razaoSocial.$model"
            :error-messages="razaoSocialErrors"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <label for=""
            >Declaro que sou cliente direto da Gali Energia
          </label>
          <div class="d-flex justify-center">
            <v-switch
              v-model="clienteDireto"
              inset
              :label="`${clienteDireto ? 'SIM, Sou cliente direto' : 'NÃO, não sou cliente direto'}`"
            ></v-switch>
          </div>
        </v-col>
        
        <v-col
          cols="12" 
          v-if="!clienteDireto"
        >
          <label for="">Nome do cliente direto</label>
          <v-text-field
            label="Cliente"
            placeholder="Razão social Cliente direto"
            solo
            v-model.trim="$v.razaoSocialDireto.$model"
            :error-messages="razaoSocialDiretoErrors"
          ></v-text-field>
        </v-col>



        <v-col
          cols="6"
        >
          <label for="">Proposta</label>
          <v-text-field
            label="Proposta"
            placeholder="Proposta"
            solo
            v-model.trim="$v.proposta.$model"
            :error-messages="propostaErrors"
          ></v-text-field>
        </v-col>

        <v-col
          cols="6"
        >
          <label for="">Projeto</label>
          <v-text-field
            label="Projeto"
            placeholder="Projeto"
            solo
            v-model.trim="$v.projeto.$model"
            :error-messages="projetoErrors"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
        >
          <label for="">Contato telefônico:</label>
          <v-text-field
            label="Contato telefônico:"
            placeholder="Tel"
            type="tel"
            pattern="(?:(?:\+|0)?([1-9][0-9])\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))"
            solo
            v-model.trim="$v.contato.$model"
            :error-messages="contatoErrors"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
        >
          <label for="">Endereço de e-mail:</label>
          <v-text-field
            label="Endereço de e-mail:"
            placeholder="E-mail"
            v-model.trim="$v.email.$model"
            :error-messages="emailErrors"
            type="email"
            solo
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
        >
          <label for="">Endereço de e-mail do responsável de obra:</label>
          <v-text-field
            label="Endereço de e-mail de um responsável da obra:"
            placeholder="E-mail"
            v-model.trim="$v.emailResponsavelObra.$model"
            :error-messages="emailResponsavelObraErrors"
            type="email"
            solo
          ></v-text-field>
        </v-col>
          <v-btn color="secondary" @click="proximaPagina" class="mb-12 ml-4"> Próximo </v-btn>

      </v-row>
</template>

<script>
import { required, minLength, maxLength, email, helpers, requiredUnless } from 'vuelidate/lib/validators'
  const telefone = helpers.regex('telefone', /^\d{2}( )|((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4,5})$/)
  ///\d{2}( )|((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4,5})/gm
export default {
    name: "InfoInicial",
    props:{
      dados: Object
    },
    data() {
      return {
        nome: '',
        cargo: '',
        razaoSocial: '',
        proposta: '',
        projeto: '',
        contato: '',
        emailResponsavelObra: '',
        email: '',
        clienteDireto: true, 
        razaoSocialDireto: '',
        cargos: [
          "Auxiliar técnico", 
          "Assistente técnico", 
          "Analista", 
          "Supervisor", 
          "Coordenador", 
          "Gerente", 
          "Diretor", 
          "Técnico de Manutenção"
        ],
      }
    },
    validations: {
      nome: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      cargo: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      razaoSocial: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      razaoSocialDireto: {
        required: requiredUnless('clienteDireto'),
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      proposta: {
        required: requiredUnless('temProjeto'),
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      projeto: {
        required: requiredUnless('temProposta'),
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      contato: {
        required,
        telefone,
      },
      emailResponsavelObra: {
        required,
        email,
      },
      email: {
        required,
        email,
      }
    },
    methods:{
      proximaPagina(){
        if(this.submit()){
          this.$emit('update:dados', {
              nome: this.nome,
              cargo: this.cargo,
              razaoSocial: this.razaoSocial,
              proposta: this.proposta,
              projeto: this.projeto,
              email: this.email,
              contato : this.contato,
              emailResponsavelObra: this.emailResponsavelObra,
              clienteDireto: this.clienteDireto,
              razaoSocialDireto: this.razaoSocialDireto,
            })
          this.$emit('changePagina', 2)
        }
      },
      submit() {
        this.$v.$touch()
        return !this.$v.$invalid
      }
    },
    computed: {
      temProposta(){
        return this.proposta.length
      },
      temProjeto(){
        return this.projeto.length
      },

      // VALIDATIONS
      nameErrors () {
        const errors = []
        if (!this.$v.nome.$dirty) return errors
        !this.$v.nome.minLength && errors.push('O campo deve conter pelo menos 3 caracteres ')
        !this.$v.nome.maxLength && errors.push('O campo deve conter no máximo 50 caracteres')
        !this.$v.nome.required && errors.push('Campo obrigatório')
        return errors
      },
      cargoErrors () {
        const errors = []
        if (!this.$v.cargo.$dirty) return errors
        !this.$v.cargo.minLength && errors.push('O campo deve conter pelo menos 3 caracteres ')
        !this.$v.cargo.maxLength && errors.push('O campo deve conter no máximo 50 caracteres')
        !this.$v.cargo.required && errors.push('Campo obrigatório')
        return errors
      },
      razaoSocialErrors () {
        const errors = []
        if (!this.$v.razaoSocial.$dirty) return errors
        !this.$v.razaoSocial.minLength && errors.push('O campo deve conter pelo menos 3 caracteres ')
        !this.$v.razaoSocial.maxLength && errors.push('Nome deve conter no máximo 50 caracteres')
        !this.$v.razaoSocial.required && errors.push('Campo obrigatório')
        return errors
      },
      razaoSocialDiretoErrors () {
        const errors = []
        if (!this.$v.razaoSocial.$dirty) return errors
        !this.$v.razaoSocialDireto.minLength && errors.push('O campo deve conter pelo menos 3 caracteres ')
        !this.$v.razaoSocialDireto.maxLength && errors.push('Nome deve conter no máximo 50 caracteres')
        !this.$v.razaoSocialDireto.required && errors.push('Campo obrigatório')
        return errors
      },
      propostaErrors () {
        const errors = []
        if (!this.$v.proposta.$dirty) return errors
        !this.$v.proposta.minLength && errors.push('O campo deve conter pelo menos 3 caracteres ')
        !this.$v.proposta.maxLength && errors.push('O campo deve conter no máximo 50 caracteres')
        !this.$v.proposta.required && errors.push('Campo obrigatório')
        return errors
      },
      projetoErrors () {
        const errors = []
        if (!this.$v.projeto.$dirty) return errors
        !this.$v.projeto.minLength && errors.push('O campo deve conter pelo menos 3 caracteres ')
        !this.$v.projeto.maxLength && errors.push('O campo deve conter no máximo 50 caracteres')
        !this.$v.projeto.required && errors.push('Campo obrigatório')
        return errors
      },
      contatoErrors () {
        const errors = []
        if (!this.$v.contato.$dirty) return errors
        !this.$v.contato.telefone && errors.push('Por favor, digite um contato válido')
        !this.$v.contato.required && errors.push('Campo obrigatório')
        return errors
      },
     emailResponsavelObraErrors () {
        const errors = []
        if (!this.$v.emailResponsavelObra.$dirty) return errors
        !this.$v.emailResponsavelObra.email && errors.push('Por favor, digite um email válido')
        !this.$v.emailResponsavelObra.required && errors.push('Campo obrigatório')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Por favor, digite um email válido')
        !this.$v.email.required && errors.push('Campo obrigatório')
        return errors
      },
    }
  }
</script>

<style lang="scss">
.form-principal{
  display: flex;
  flex-direction: column;
}
</style>
